<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">老师管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input v-model="searchForm.teacherName" clearable size="small" placeholder="请输入姓名"></el-input>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="addTeacher()">新增</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader">
              <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" width="100px" />
              <el-table-column label="姓名" align="center" show-overflow-tooltip prop="teacherName" />
              <el-table-column label="角色类型" align="center" show-overflow-tooltip prop="roleName" />
              <el-table-column label="手机号" align="center" show-overflow-tooltip prop="mobile" />
              <el-table-column label="身份证号" align="center" show-overflow-tooltip prop="idCard" />
              <el-table-column label="创建时间" align="center" show-overflow-tooltip prop="createTime" />
              <el-table-column label="登录账号" align="center" show-overflow-tooltip prop="loginAccount" />
              <el-table-column label="状态" align="center" show-overflow-tooltip prop="state">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.state" @change="stateChange(scope.row)" active-value="10" inactive-value="20" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" min-width="160" show-overflow-tooltip>
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding:0px 5px" size="mini" @click="addTeacher(scope.row.teacherId)">编辑</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini" @click="resetPassword(scope.row)">重置密码</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini" :disabled="scope.row.lock == false" @click="handleLock(scope.row.loginAccount)">解除锁定</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!-- 新增 - 弹框 -->
    <el-dialog :title="dialogData.dialogTitle" :visible.sync="dialogData.dialogState" v-if="dialogData.dialogState" :close-on-click-modal="false" width="600px" @close="closeModel">
      <el-form ref="dialogData" :model="dialogData" label-width="100px" :rules="detailRules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="姓名" prop="teacherName" label-width="120px">
              <el-input v-model="dialogData.teacherName" placeholder="请输入姓名" size="small" clearable :maxlength="30" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="手机号" prop="mobile" label-width="120px">
              <el-input v-model="dialogData.mobile" placeholder="请输入手机号" size="small" clearable :maxlength="11" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="身份证号" prop="idCard" label-width="120px">
              <el-input v-model="dialogData.idCard" placeholder="请输入身份证号" size="small" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="登录账号" prop="loginAccount" label-width="120px">
              <el-input v-model="dialogData.loginAccount" placeholder="请输入登录账号" size="small" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="角色类型" prop="teacherRoleId" label-width="120px">
              <el-select v-model="dialogData.teacherRoleId" clearable size="small" placeholder="请选择角色类型">
                <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="" label-width="120px">
              账号的默认密码为Aa+手机号后六位
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeModel" size="small">取 消</el-button>
        <el-button type="primary" @click="addTrainingConfirm" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "examination_onlineTest_invigilator",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data () {
    return {
      searchForm: {
        teacherName: '',
      },
      dialogData: {
        dialogTitle: '新增老师',
        dialogState: false,
        teacherName: '',
        mobile: '',
        idCard: '',
        loginAccount: '',
        teacherRoleId: '',
        teacherId: '',
      },
      detailRules: {
        teacherName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        mobile: [
          { required: true, validator: this.$validatePhone, trigger: "blur" },
        ],
        idCard: [
          { required: true, validator: this.$validateIdCard, trigger: "blur" },
        ],
        loginAccount: [
          { required: true, message: "请输入登录账号", trigger: "blur" },
        ],
        teacherRoleId: [
          { required: true, message: "请选择角色类型", trigger: "change" },
        ],
      },
      roleList: []
    };
  },
  created () {
    this.getRoleList()
  },
  mounted () {
  },
  methods: {
    getRoleList () {
      this.$get("/biz/exam/teacher/role/list")
        .then((res) => {
          this.roleList = res.data
        })
    },
    getData (pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.searchForm.teacherName) {
        params.teacherName = this.searchForm.teacherName;
      }
      this.doFetch({
        url: "/biz/exam/teacher/pageList",
        params,
        pageNum,
      });
    },
    closeModel () {
      this.dialogData = this.$options.data().dialogData
    },
    stateChange (row) {
      //设置状态重置点击前
      if (row.state === '10'){
        row.state = '20'
      } else {
        row.state = '10'
      }
      this.$post("/biz/exam/teacher/updateState", {
        teacherId: row.teacherId,
      }, 3000, false)
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: ret.message,
              type: 'success'
            })
            if (row.state === '10'){
              row.state = '20'
            } else {
              row.state = '10'
            }
          }
        })
        .catch((err) => {
        });
    },
    resetPassword ({ teacherId }) {
      this.$confirm('是否将用户账号的密码重置？<br> 重置后，变为初始密码Aa+手机号后六位', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(() => {
        this.$post("/biz/exam/teacher/restPassword", {
          teacherId,
        })
          .then((ret) => {
            if (ret.status == "0") {
              this.$message({
                message: ret.message,
                type: 'success'
              })
              this.getData(-1);
            }
          })
          .catch((err) => {
          });
      }).catch(() => { });
    },
    handleLock (adminName) {
      this.$confirm("账号是否解除锁定？", "解除锁定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/sys/admin/unlock", { adminName }).then((res) => {
            if (res.status == "0") {
              this.$message({
                type: "success",
                message: "解锁成功!",
              });
              this.getData(-1);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消解除锁定",
          });
        });
    },
    // 新增老师
    async addTeacher (teacherId) {
      if (teacherId) {
        await this.$post('/biz/exam/teacher/detail', { teacherId }).then((res) => {
          Object.keys(this.dialogData).forEach((key) => {
            this.dialogData[key] = res.data[key];
          });
        })
        this.dialogData.dialogTitle = "编辑老师";
      } else {
        this.dialogData.dialogTitle = "新增老师";
        this.dialogData.teacherId = ""; //id
      }
      this.dialogData.dialogState = true;
    },
    // 新增/编辑 - 确认
    addTrainingConfirm () {
      this.$refs.dialogData.validate((valid) => {
        if (valid) {
          let url
          if (this.dialogData.teacherId) {
            url = '/biz/exam/teacher/update'
          } else {
            url = '/biz/exam/teacher/insert'
          }
          this.$post(url, this.dialogData)
            .then((res) => {
              if (res.status == 0) {
                this.closeModel()
                this.$message.success(res.message);
                this.getData(-1);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          return false;
        }
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {

  }
};
</script>
<style lang="less" scoped>
.searchBox {
  padding: 0 !important;
  .el-icon-arrow-down:before {
    content: '\e6df' !important;
  }
}
</style>
